export const buttonGroupStates = {
  saved: 'saved',
  saving: 'saving',
  submittable: 'submitable',
  submitting: 'submitting',
  unsaved: 'unsaved',
  untouchedEmptyForm: 'untouchedEmptyForm',
  validatable: 'validatable',
  validated: 'validated',
  validating: 'validating',
}
